@charset "utf-8";

@keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		display: block;
		opacity: 1;
	}

	99% {
		display: block;
		opacity: 0;
	}

	100% {
		display: none;
		opacity: 0;
	}
}
// @keyframes zoom-in {
// 	0% {
// 		transform: scale(1);
// 	}
// 	100% {
// 		transform: scale(1.5);
// 	}
// }
// @keyframes zoom-in-hover {
// 	0% {
// 		transform: scale(1);
// 	}
// 	100% {
// 		transform: scale(1.1);
// 	}
// }

// @keyframes sub_menu_fade_in {
// 	0% { opacity: 0; }
// 	100% { opacity: .5; }
// }

// @keyframes pop-up {
// 	0% {
// 		transform: scale(1);
// 	}
// 	100% {
// 		transform: scale(1.1);
// 	}
// }

@keyframes _on_opacity {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

// @keyframes slide-up {
// 	0% {
// 		transform: translateY(100%);
// 	}
// 	100% {
// 		transform: translateY(0);
// 	}
// }

@keyframes header_index_show {
	0% {
		display: none;
	}

	1% {
		display: block;
		opacity: 0;
		top: -30px;
	}

	100% {
		top: 0;
		opacity: 1;
		display: block;
	}
}

@keyframes header_index_hide {
	0% {
		top: 0;
		opacity: 1;
		display: block;
	}

	99% {
		top: -30px;
		opacity: 0;
		display: block;
	}

	100% {
		top: -30px;
		opacity: 0;
		display: none;
	}
}

@keyframes header_search_show {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(64px);
	}
}

@keyframes header_search_hide {
	0% {
		transform: translateY(64px);
	}

	100% {
		transform: translateY(0);
	}
}
